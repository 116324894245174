import { useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import EditBio from '../../modals/EditBio';
import axiosWrapper from '../../helpers/axiosWrapper';
const DriverBio = () => {
  const [isEditBioOpen, setEditBioOpen] = useState(false); // State to control the modal visibility
  const [bio, setBio] = useState("Driver is awesome!"); // Initial bio content
  const handleSaveBio = async (newBio) => {
    setBio(newBio); // Update the bio state
    // Retrieve access token from localStorage
    const accessToken = localStorage.getItem('access_token');
    console.log('got access token:', accessToken);
    try {
      // Make API call with the access token
      const response = await axiosWrapper.post(
        'https://api.rideconnectx.com/api/update-bio',
        { bio: newBio },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`, // Send the access token in the header
            'Content-Type': 'application/json',
          }
        }
      );
      console.log(response.data); // Handle the response if needed
    } catch (error) {
      console.error('Error updating bio:', error);
    } 
  };
  return (
    <div className="p-0 bg-gray-100">
      <Disclosure defaultOpen={true} as='div' className="py-2 min-w-full">
        <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
          <span className='text-sm/6 font-medium'>
            Driver Bio
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-4 rounded-b-md">
          <p className="text-sm text-gray-700">{bio}</p>
          <button 
            className="mt-2 text-sm text-blue-600 hover:underline"
            onClick={() => setEditBioOpen(true)}
          >
            Edit Bio
          </button>
        </DisclosurePanel>
      </Disclosure>
      {/* EditBio Modal */}
      <EditBio 
        isOpen={isEditBioOpen}
        onClose={() => setEditBioOpen(false)}
        currentBio={bio}
        onSave={handleSaveBio}
      />
    </div>
  );
}
export default DriverBio;
