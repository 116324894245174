import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import UserLayout from "./components/UserLayout";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ProfilePage from "./pages/ProfilePage";
import DriverHome from "./pages/DriverHome";

import TestPage from "./pages/TestPage";
function App() {
	return (
		
		<Router>
			<AuthProvider>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/test" element={<UserLayout><TestPage /></UserLayout>} />
				<Route path="/profile" element={<UserLayout><ProfilePage /></UserLayout>} />
				<Route path="/driver-home" element={<UserLayout><DriverHome /></	UserLayout>} />
			</Routes>
			</AuthProvider>
		</Router>
	);
}
export default App;
