import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const DriverSchedule = () => {
  return (
    <div className="p-0 bg-gray-100">
      <Disclosure as='div' className="py-2 min-w-full" defaultOpen={true}>
        <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
          <span className='text-sm/6 font-medium'>
            Driver Schedule
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-4 rounded-b-md">
          <div className="text-sm text-gray-700">
            <ul>
              <li><strong>Monday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Tuesday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Wednesday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Thursday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Friday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Saturday:</strong> [Start Time] - [End Time]</li>
              <li><strong>Sunday:</strong> [Start Time] - [End Time]</li>
            </ul>
            <p><strong>Availability Notes:</strong> [Any additional notes or exceptions]</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
}

export default DriverSchedule;
