import axios from 'axios';
// Function to get JWT from localStorage
const getAuthToken = () => {
  const token = localStorage.getItem('access_token');
  return token ? `Bearer ${token}` : ''; // Return the JWT if it exists
};
// Function to handle errors, including token refresh
const handleAxiosError = async (error) => {
  if (error.response && error.response.status === 401) {
    console.warn('Token expired, attempting to refresh...');
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      try {
        const response = await axios.post('https://api.rideconnectx.com/api/refresh-token', { refreshToken }); // Assuming the refresh endpoint
        localStorage.setItem('access_token', response.data.newAccessToken); // Update access token in localStorage
        // Retry the original request with new token
        const originalRequest = error.config;
        originalRequest.headers['Authorization'] = `Bearer ${response.data.newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError.message);
        throw refreshError;
      }
    } else {
      console.error('No refresh token available');
    }
  }
  console.error('Error during request:', error.message || 'Request failed');
  throw error;
};
// Wrapper for API calls
const axiosWrapper = {
  get: async (url, params = {}) => {
    try {
      const response = await axios.get(url, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(), // Ensure JWT is included in the header
        },
      });
      return response.data;
    } catch (error) {
      return await handleAxiosError(error);
    }
  },
  post: async (url, data = {}) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(), // Ensure JWT is included in the header
        },
      });
      return response.data;
    } catch (error) {
      return await handleAxiosError(error);
    }
  },
};
export default axiosWrapper;
