import UserHeader from "./UserHeader"

const UserLayout = ({children}) => {
  return (
  <>
    <UserHeader />
    <div className="mx-auto max-w-[1200px] w-full sm:px-6 lg:px-8">{children}</div>
  </>

);
}

export default UserLayout;