import { useAuth } from '../context/AuthContext'; // Import the custom hook
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
const TestPage = () => {
  const { auth, logout } = useAuth(); // Use the custom hook to access the authentication state and logout function

  return (
    <div className='w-full'>
      <h1>Test Page</h1>
      <Disclosure as='div' className="p-6 min-w-full">
        <DisclosureButton className="group flex w-full items-center justify-between bg-gray-200 p-4 rounded-t-md">
          <span className='text-sm/6 font-medium'>
          Vehicle Information
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-8 bg-[#ddeeff] rounded-b-md">Goes VROOM!</DisclosurePanel>
      </Disclosure>


      {auth.isAuthenticated ? (
        <>
          <p>Hello, {auth.user}</p> {/* Display the user (assuming it's a string, like a username) */}
          <button onClick={logout} className="text-blue-600 hover:underline mt-4">Logout</button> {/* Logout button */}
        </>
      ) : (
        <p>Please log in to see this content.</p> 
      )}
    </div>
  );
};

export default TestPage;
