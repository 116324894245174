import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const Extras = () => {
  return (
    <div className="p-0 bg-gray-100">
        <Disclosure as='div' className="py-2 min-w-full"  defaultOpen={true}>
          <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
            <span className='text-sm/6 font-medium'>
            Extras
            </span>
            <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
          </DisclosureButton>
          <DisclosurePanel className="p-4  rounded-b-md">Has all the extras!</DisclosurePanel>
        </Disclosure>
      </div>
  );
}

export default Extras;