import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const AboutVehicle = () => {
  return (
    <div className="p-0 bg-gray-100">
      <Disclosure as='div' className="py-2 min-w-full" defaultOpen={true}>
        <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
          <span className='text-sm/6 font-medium'>
            Vehicle Information
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-4 rounded-b-md">
          <div className="text-sm text-gray-700">
            <p><strong>Make and Model:</strong> [Vehicle Make, Model]</p>
            <p><strong>Color:</strong> [Vehicle Color]</p>
            <p><strong>License Plate:</strong> [License Plate Number]</p>
            <p><strong>Capacity:</strong> [Number of Passengers]</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
}

export default AboutVehicle;
