import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { Logo } from '../components/Logo';
import { SlimLayout } from '../components/SlimLayout';
import { TextField } from '../components/Fields';
import { useNavigate } from 'react-router-dom';
export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState(""); // Fixed typo here
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null); // Corrected to reset error instead of email
    if (password !== passwordConfirm) {
      setError("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch("https://api.rideconnectx.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, email, password })
      });
      const data = await response.json();
      if (data.success) {
        setError(null);
        setLoading(false);
        // Redirect to login page
        navigate("/login"); // Changed href to to
      } else {
        setError(data.message);
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  return (
    <SlimLayout>
      <div className="flex">
        <Link to="/" aria-label="Home"> {/* Changed href to to */}
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Get started for free
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Already registered?{' '}
        <Link to="/login" className="font-medium text-blue-600 hover:underline">
          Sign in
        </Link>{' '}
        to your account.
      </p>
      <form onSubmit={handleRegister} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        <TextField className="col-span-full" label="Username" name="username" onChange={(e) => setUsername(e.target.value)} type="text" autoComplete="name" required />
        <TextField className="col-span-full" label="Email address" name="email" onChange={(e) => setEmail(e.target.value)} type="email" autoComplete="email" required />
        <TextField className="col-span-full" label="Password" name="password" onChange={(e) => setPassword(e.target.value)} type="password" autoComplete="new-password" required />
        <TextField className="col-span-full" label="Confirm Password" name="passwordConfirm" onChange={(e) => setPasswordConfirm(e.target.value)} type="password" autoComplete="new-password" required />
        <div className="col-span-full">
          <Button type="submit" variant="solid" color="blue" className="w-full">
            <span>
              Sign up <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        </div>
      </form>
    </SlimLayout>
  );
}
