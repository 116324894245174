import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const Amenities = () => {
  return (
    <div className="p-0 bg-gray-100">
      <Disclosure as='div' className="py-2 min-w-full" defaultOpen={true}>
        <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
          <span className='text-sm/6 font-medium'>
            Amenities
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-4 rounded-b-md">
          <div className="text-sm text-gray-700">
            <ul>
              <li>• Air Conditioning</li>
              <li>• Free Wi-Fi</li>
              <li>• USB Charging Ports</li>
              <li>• Pet-Friendly</li>
              <li>• Child Seat Available</li>
              <li>• Complimentary Water</li>
            </ul>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
}

export default Amenities;
