

import { Container } from './Container'
import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'How much does it cost?',
      answer:
        '$10 a month and that is it. No percentages, nickel and diming, or hidden fees.',
    },
    {
      question: 'Can I set my own prices?',
      answer: 'Absolutely, you are more that welcome to set your own prices. We do not restrict this in any way.',
    },
    {
      question: 'Am I obligated to accepts rides?',
      answer:
        'Nope. In fact, we do not even keep track of how many rides you accept or decline.',
    },
  ],
  [
    {
      question: 'What percentage do you take?',
      answer:
        'We do not take a percentage of your earnings. We only charge a flat fee of $10 a month. In fact, we do not even have a way to take a percentage of your earnings as payments are between you and the rider.',
    },
    {
      question:
        'How do you handle ratings and feedback?',
      answer:
        'We do share ratings in effort to keep the community safe, but we do not have restriction system.',
    },
    {
      question:
        'Won\'t this idea piss off Uber/Lyft/etc?',
      answer:
        'Probably.',
    },
  ],
  [
    {
      question: 'What makes this place any different?',
      answer:
        'It was literally created by Ride Share drivers who were tired of the way things were being done.',
    },
    {
      question: 'Can we expect more features?',
      answer: 'We do plan on adding more things in the future, however we are committed to keeping things simple and easy to use. We also want to keep the pricing simple and trnasparent without any nonsense. So that will be a factor, but yes we have a few ideas in the works.',
    },
    {
      question: 'So you\'re going to increase the price once the platform grows, right?',
      answer:
        'No. We are committed to keeping the price at $10 a month. We are not interested in making a quick buck. We are interested in making a platform that is fair and easy to use for everyone. The only things that would increase the price is something that incurred a cost to us, such as a background check or something like that.',
    },
  ],
]

export function Faqs() {
  return (
    <section id="faq" aria-labelledby="faq-title" className="relative overflow-hidden bg-slate-50 py-20 sm:py-32" >
      <img className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]" src={backgroundImage} alt="" width={1558} height={946} loading="lazy" />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 id="faq-title" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl" >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team
            and if you’re lucky someone will get back to you.
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3" >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
