import AboutVehicle from "../components/profile/AboutVehicle";
import AboutDriver from "../components/profile/AboutDriver";
import Amenities from "../components/profile/Amenities";
import RatesBox from "../components/profile/RatesBox";
import Extras from "../components/profile/Extras";
import DriverSchedule from "../components/profile/DriverSchedule";
import DriverBio from "../components/profile/DriverBio";

const ProfilePage = () => {
  return (
    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
      {/* Profile Content */}
      <div className="p-6 bg-white">
        <div className="flex items-center">
          {/* Avatar */}
          <div className="w-24 h-24 rounded-full border-4 border-white">
            <img
              className="rounded-full object-cover w-full h-full"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
              alt="Driver Avatar"
            />
          </div>
          {/* Name, Rating, Bio */}
          <div className="ml-4">
            <h2 className="text-xl font-semibold text-gray-800">Jane Doe</h2>
            <p className="text-gray-600">Experienced Local Driver</p>
            {/* Rating */}
            <div className="flex items-center mt-1">
              <span className="text-yellow-500">★★★★☆</span>
              <p className="ml-2 text-gray-500 text-sm">4.5 (200 reviews)</p>
            </div>
          </div>
        </div>
        {/* Contact and Availability */}
        <div className="mt-4 flex items-center justify-between">
          {/* Contact Button */}
          <button className="bg-green-500 text-white rounded px-4 py-2">
            Contact
          </button>
          {/* Availability Status */}
          <span className="text-gray-500">Available Now</span>
        </div>
      </div>
      {/* Driver Bio */}
      <DriverBio />
      {/* About Driver */}
      <AboutDriver />
      {/* About Vehicle */}
      <AboutVehicle />
      {/* Amenities */}
      <Amenities />
      {/* Rates Box */}
      <RatesBox />
      {/* Extras */}
      <Extras />
      {/* Driver Schedule */}
      <DriverSchedule />
    </div>
  );
};

export default ProfilePage;

