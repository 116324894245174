import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosWrapper from '../helpers/axiosWrapper';
import { useNavigate } from 'react-router-dom';

// Create AuthContext
const AuthContext = createContext();
const useAuth = () => useContext(AuthContext);

// Create a provider component
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(() => {
    // Initialize state from localStorage
    const storedAuth = localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : { isAuthenticated: false, user: null };
  });
  const [authError, setAuthError] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);

  useEffect(() => {
    // Save auth state to localStorage whenever it changes
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  // Function to handle login
  const login = async (username, password) => {
    setAuthError(null);
    setAuthLoading(true);
    try {
      const response = await axiosWrapper.post("https://api.rideconnectx.com/api/login", { username, password });
      if (response.success) {
        // Save tokens in localStorage
        localStorage.setItem('access_token', response.accessToken);
        localStorage.setItem('refresh_token', response.refreshToken);

        // Update auth state
        setAuth({ isAuthenticated: true, user: response.message });
        setAuthLoading(false);
        navigate('/driver-home');
      } else {
        setAuthError(response.message);
        setAuthLoading(false);
      }
    } catch (error) {
      setAuthError(error.message);
      setAuthLoading(false);
    }
  };

  // Function to handle logout
  const logout = async () => {
    // Invalidate the refresh token server-side if necessary
    try {
      await axiosWrapper.post("https://api.rideconnectx.com/api/logout");
    } catch (error) {
      console.error('Logout failed:', error);
    }
    // Clear auth state and remove tokens
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setAuth({ isAuthenticated: false, user: null });
  };

  // Function to handle token refresh
  const refreshAuth = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      const response = await axiosWrapper.post("https://api.rideconnectx.com/api/refresh-token", { refreshToken });

      if (response.success) {
        // Update tokens and auth state
        localStorage.setItem('access_token', response.newAccessToken);
        setAuth({ isAuthenticated: true, user: response.message });
      } else {
        logout(); // Logout if refresh failed
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();
    }
  };

  // Function to automatically refresh tokens if needed
  useEffect(() => {
    // Check if the user is authenticated and periodically refresh the token
    if (auth.isAuthenticated) {
      const interval = setInterval(() => {
        refreshAuth(); // Refresh token periodically
      }, 15 * 60 * 1000); // Every 15 minutes
      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [auth.isAuthenticated]);

  return (
    <AuthContext.Provider value={{ auth, login, logout, authError, authLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth };
