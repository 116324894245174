import { useAuth } from '../context/AuthContext'; 

const DriverHome = () => {
  const { auth, logout } = useAuth(); 

  return (
    <div>
    {auth.isAuthenticated ? (
      <>
        <p>Hello, {auth.user}</p> {/* Display the user (assuming it's a string, like a username) */}
        <button onClick={logout} className="text-blue-600 hover:underline mt-4">Logout</button> {/* Logout button */}
      </>
    ) : (
      <p>Please log in to see this content.</p> 
    )}
    </div>
  );
}

export default DriverHome;