import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
const EditBio = ({ isOpen, onClose, currentBio, onSave }) => {
  const [bio, setBio] = useState(currentBio);
  const handleSave = () => {
    onSave(bio);  // Trigger save function passed from parent
    onClose();    // Close the modal
  };
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >
            <Dialog.Panel className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg">
              <Dialog.Title className="text-lg font-medium text-gray-900">Edit Bio</Dialog.Title>
              <div className="mt-4">
                <textarea
                  className="w-full h-32 p-2 border border-gray-300 rounded-md"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Enter your bio..."
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button className="mr-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700" onClick={handleSave} >
                  Save
                </button>
                <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300" onClick={onClose} >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default EditBio;
