import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const RatesBox = () => {
  return (
    <div className="p-0 bg-gray-100">
      <Disclosure as='div' className="py-2 min-w-full" defaultOpen={true}>
        <DisclosureButton className="group flex w-full items-center justify-between bg-white px-4 py-2 rounded-t-lg">
          <span className='text-sm/6 font-medium'>
            Rates
          </span>
          <ChevronDownIcon className='size-5 fill-black/60 group-data-[open]:rotate-180' />
        </DisclosureButton>
        <DisclosurePanel className="p-4 rounded-b-md">
          <div className="text-sm text-gray-700">
            <p><strong>Base Rate:</strong> $[Base Rate]</p>
            <p><strong>Per Mile Rate:</strong> $[Per Mile Rate]</p>
            <p><strong>Per Minute Rate:</strong> $[Per Minute Rate]</p>
            <p><strong>Cancellation Fee:</strong> $[Cancellation Fee]</p>
            <p><strong>Additional Charges:</strong> [Any additional fees, like tolls or special requests]</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
}

export default RatesBox;
