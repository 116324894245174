import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { TextField } from '../components/Fields';
import { Logo } from '../components/Logo';
import { SlimLayout } from '../components/SlimLayout';
import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, authLoading, authError } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    await login(username, password);
  };

  return (
    <SlimLayout>
      <div className="flex">
        <Link to="/" aria-label="Home">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Sign in to your account
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Don’t have an account?{' '}
        <Link to="/register" className="font-medium text-blue-600 hover:underline">
          Sign up
        </Link>{' '}
        for a free trial.
      </p>
      <form onSubmit={handleLogin} className="mt-10 grid grid-cols-1 gap-y-8"> {/* Use onSubmit instead */}
        <TextField label="Username" name="username" type="text" autoComplete="email" required onChange={(e) => setUsername(e.target.value)} />
        <TextField label="Password" name="password" type="password" autoComplete="current-password" required onChange={(e) => setPassword(e.target.value)} />
        <div>
          <Button type="submit" variant="solid" color="blue" className="w-full" disabled={authLoading}>
            <span>
              {authLoading ? "Signing in..." : "Sign in"} <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        </div>
      </form>
      {authError && <p className="mt-4 text-red-600">{authError}</p>}
    </SlimLayout>
  );
}
